<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">ใช้บริการ</h4>
      </div>

      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="4">
                <h5>
                  <v-icon large>mdi-account-circle-outline</v-icon>
                  ข้อมูลลูกค้า
                </h5>
                <div class="d-flex flex-column justify-center align-center pt-3">
                  <v-avatar size="184">
                    <img
                      src="https://cdn.vuetifyjs.com/images/john.jpg"
                      alt="John"
                    >
                  </v-avatar>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="5">
                    <v-select
                      :items="itemsCustomer"
                      v-model="customerData.name"
                      item-text="name"
                      item-value="name"
                      label="ชื่อลูกค้า"
                      placeholder="เลือกลูกค้า"
                      no-data-text="ยังไม่มีข้อมูล"
                      class="pt-0"
                      single-line
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="3">
                    <v-select
                      :items="itemsCustomerBelongTo"
                      v-model="customerData.belongto"
                      item-text="name"
                      item-value="name"
                      label="ความเกี่ยวข้อง"
                      placeholder="เลือกความเกี่ยวข้อง"
                      no-data-text="ยังไม่มีข้อมูล"
                      class="pt-0"
                      single-line
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="0" md="0" lg="4" />
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">รหัสสมาชิก</span>
                    <span class="label-customer-data">{{customerData.code || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">ชื่อ - สกุล</span>
                    <span class="label-customer-data">{{customerData.fullname || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">ชื่อเล่น</span>
                    <span class="label-customer-data">{{customerData.nickname || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">วันเกิด</span>
                    <span class="label-customer-data">{{moment(customerData.birthdate).add(543, 'year').format('DD / MM / YYYY') || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">อายุ</span>
                    <span class="label-customer-data">{{`${computedCustomerAge() || '-'} ปี`}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">เพศ</span>
                    <span class="label-customer-data">{{customerData.gender === 'male' ? 'ผู้ชาย' : customerData.gender === 'female' ? 'ผู้หญิง' : 'ไม่ระบุ '}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">อาชีพ</span>
                    <span class="label-customer-data">{{customerData.occupation}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">ที่อยู่</span>
                    <span class="label-customer-data">{{customerData.address || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">เบอร์โทรศัพท์</span>
                    <span class="label-customer-data">{{customerData.phonenumber || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">เลขบัตรประชาชน</span>
                    <span class="label-customer-data">{{customerData.idcardnumber || '-'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">แพ้ยา/แพ้อาหาร</span>
                    <span class="label-customer-data">{{customerData.allergy || 'ไม่มี'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">อื่นๆ ประวัติการใช้ยาที่ต้านการแข็งตัวของเลือด (Warfarin, Vitamin E, Aspirin)</span>
                    <span class="label-customer-data">{{customerData.otherallergy || 'ไม่มี'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">ประวัติการผ่าตัด/ฟิลเลอร์</span>
                    <span class="label-customer-data">{{customerData.surgeryhistory || 'ไม่มี'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">ตั้งครรภ์และให้นมบุตร</span>
                    <span class="label-customer-data">{{customerData.pregnant || 'ไม่'}}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="3" class="d-flex flex-column">
                    <span class="title-customer-data">โรคประจำตัว</span>
                    <span class="label-customer-data">{{customerData.congenital || 'ไม่มี'}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>
              <v-icon large>mdi-auto-fix</v-icon>
              หัตถการ/คอร์ส
            </h5>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-select
                  :items="itemsOperativecourse"
                  v-model="OperativecourseData.name"
                  item-text="name"
                  item-value="name"
                  label="หัตถการ/คอร์ส"
                  placeholder="เลือกหัตถการ/คอร์ส"
                  no-data-text="ยังไม่มีข้อมูล"
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-btn
                  color="warning"
                  class="mt-3"
                  @click="editOperativecourseData"
                >
                  <v-icon class="mr-3">mdi-square-edit-outline</v-icon>
                  แก้ไขรายละเอียดคอร์ส
                </v-btn>
              </v-col>
            </v-row>
            <v-card-title class="mt-3">
              <v-row>
                <v-spacer/>
                <v-btn
                  text
                  color="success"
                  @click="addOperativecourseData"
                >
                  เพิ่ม
                  <v-icon class="ml-3">mdi-plus-circle</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-data-table
              :headers="headersOperativecourse"
              :items="dataOperativecourse"
              :loading="loaddataOperativecourse"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageOperativecourse
              }"
            >
              <template v-slot:item.operativecourse_code="{ item }">
                <div class="text-start">{{item.operativecourse_code}}</div>
              </template>
              <template v-slot:item.operativecourse_name="{ item }">
                <div class="text-start">{{item.operativecourse_name}}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.operativecourse_selection_bool"
                    label=""
                    class="pt-0 mt-0"
                    readonly
                    single-line
                    hide-details
                  ></v-checkbox>
                </div>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initOperativecourseData"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>
              <v-icon large>mdi-card-account-details</v-icon>
              รายการใช้บริการ
            </h5>
            <v-data-table
              :headers="headersServiceList"
              :items="dataServiceList"
              :loading="loaddataServiceList"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageServiceList
              }"
              class="mt-3"
            >
              <template v-slot:item.servicelist_code="{ item }">
                <div class="text-start">{{item.servicelist_code}}</div>
              </template>
              <template v-slot:item.servicelist_name="{ item }">
                <div class="text-start">{{item.servicelist_name}}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  @click="deleteServiceListItem(item)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initServiceListData"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
            <v-row class="py-3">
              <v-col cols="12" sm="12" md="12" lg="8">
                <v-select
                  :items="itemsServiceList"
                  v-model="addServiceList.assistant_name"
                  item-text="name"
                  item-value="name"
                  label="พนักงานผู้ช่วย"
                  placeholder="เลืือกพนักงานผู้ช่วย"
                  no-data-text="ยังไม่มีข้อมูล"
                  outlined
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2">
                <div class="text-center label-customer-data">
                  {{addServiceList.amount}}
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="2">
                <div class="d-flex justify-center align-center">
                  <v-btn
                    icon
                    color="success"
                    @click="deleteServiceListItem(item)"
                  >
                    <v-icon large>mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="3" class="d-flex flex-column">
                <span class="label-customer-data">หมายเหตุ</span>
                <span class="title-customer-data">(กรณีหากมีการเพิ่ม หรือลดอุปกรณ์และยา)</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="9">
                <v-textarea
                  outlined
                  class="mt-3"
                  name="input-7-4"
                  label=""
                  :value="addServiceList.notation"
                  single-line
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="d-flex flex-row align-center justify-end">
              <v-btn
                color="success"
                class="mr-3"
                @click="addServiceListFunc()"
              >
                <v-icon class="mr-3">mdi-credit-card-outline</v-icon>
                ใช้บริการ
              </v-btn>
              <v-btn
                color="error"
                @click="cancelServiceListFunc()"
              >
                <v-icon class="mr-3">mdi-close-circle</v-icon>
                ยกเลิก
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>รายการคอร์ส</h5>
            <v-row class="my-1">
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-select
                  :items="itemsCourseList"
                  v-model="select_filter_courselist"
                  item-text="courselist_name"
                  item-value="courselist_code"
                  label="หัตถการ/คอร์ส"
                  placeholder="เลืือกหัตถการ/คอร์ส"
                  no-data-text="ยังไม่มีข้อมูล"
                  outlined
                  single-line
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headersCourseList"
              :items="dataCourseList"
              :loading="loaddataCourseList"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageCourseList
              }"
              :search="select_filter_courselist"
            >
              <template v-slot:item.courselist_code="{ item }">
                <div class="text-start">{{item.courselist_code}}</div>
              </template>
              <template v-slot:item.courselist_name="{ item }">
                <div class="text-start">{{item.courselist_name}}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  @click="editCourseListItem(item)"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initCourseListData"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>แก้ไขรายละเอียด รายการยาและอุปกรณ์ที่ใช้</h5>
            <span class="label-customer-data">{{title_select_filter_courselist}}</span>
            <v-data-table
              :headers="headersEditCourseList"
              :items="dataEditCourseList"
              :loading="loaddataEditCourseList"
              loading-text="กำลังโหลด..."
              :options="{
                'itemsPerPage': itemsPerPageEditCourseList
              }"
              class="mt-3"
            >
              <template v-slot:item.editcoursedrug_name="{ item }">
                <div class="text-start">
                  <span class="font-weight-medium mr-3">{{item.editcoursedrug_type}}</span>
                  <span class="font-weight-light">{{item.editcoursedrug_name}}</span>
                </div>
              </template>
              <template v-slot:item.editcoursedrug_use="{ item }">
                <v-text-field
                  v-model="item.editcoursedrug_use"
                  label=""
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  @click="deleteEditCourseListItem(item)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                กรณีหากมีการแก้ไขรายละเอียดยาและอุปกรณ์
              </template>
            </v-data-table>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-select
                  :items="itemsDrug"
                  v-model="select_add_drug_courselist"
                  item-text="name"
                  item-value="name"
                  label="ยาที่ต้องการเพิ่มในคอร์ส"
                  placeholder="เลืือกยาที่ต้องการเพิ่มในคอร์ส"
                  no-data-text="ยังไม่มีข้อมูล"
                  outlined
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="d-flex justify-start align-center">
                <v-btn
                  color="secondary"
                  @click="addDrugToCourseList()"
                  min-width="150"
                  :disabled="canaddtoeditcourselist"
                >
                  <v-icon class="mr-3">mdi-plus-circle</v-icon>
                  เพิ่มยา
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-select
                  :items="itemsMedical"
                  v-model="select_add_medical_courselist"
                  item-text="name"
                  item-value="name"
                  label="อุปกรณ์ที่ต้องการเพิ่มในคอร์ส"
                  placeholder="เลืือกอุปกรณ์ที่ต้องการเพิ่มในคอร์ส"
                  no-data-text="ยังไม่มีข้อมูล"
                  outlined
                  single-line
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" class="d-flex justify-start align-center">
                <v-btn
                  color="secondary lighten-1"
                  @click="addMedicalToCourseList()"
                  min-width="150"
                  :disabled="canaddtoeditcourselist"
                >
                  <v-icon class="mr-3">mdi-plus-circle</v-icon>
                  เพิ่มอุปกรณ์
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'

export default {
  data: () => ({
    customerData: {
      code: 'xxxx123456789',
      belongto: 'เป็นเจ้าของคอร์ส',
      name: 'นางสาว xxxxx',
      fullname: 'นางสาว xxxxx xxxxxxxxxx',
      nickname: 'บี',
      birthdate: '1989-05-16',
      age: 0,
      gender: 'female',
      occupation: 'ธุรกิจส่วนตัว'
    },
    itemsCustomer: [{
      _id: '000',
      name: 'นางสาว xxxxx'
    }],
    itemsCustomerBelongTo: [{
      _id: '000',
      name: 'เป็นเจ้าของคอร์ส',
      value: 'owner'
    }],
    OperativecourseData: {},
    itemsOperativecourse: [],
    loaddataOperativecourse: false,
    dataOperativecourse: [],
    itemsPerPageOperativecourse: 5,
    loaddataServiceList: false,
    itemsServiceList: [],
    dataServiceList: [],
    itemsPerPageServiceList: 5,
    addServiceList: {
      assistant_name: '',
      amount: 1
    },
    loaddataCourseList: false,
    itemsCourseList: [],
    dataCourseList: [],
    itemsPerPageCourseList: 5,
    select_filter_courselist: '',
    title_select_filter_courselist: '',
    loaddataEditCourseList: false,
    dataEditCourseList: [],
    itemsPerPageEditCourseList: 5,
    itemsDrug: [],
    itemsMedical: [],
    select_add_drug_courselist: '',
    select_add_medical_courselist: '',
    canaddtoeditcourselist: true
  }),
  computed: {
    headersOperativecourse () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          value: 'operativecourse_code',
          sortable: false
        }, {
          text: 'ชื่อคอร์ส/หัตถการ',
          align: 'center',
          value: 'operativecourse_name',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'operativecourse_amount',
          sortable: false
        }, {
          text: 'เลือก',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersServiceList () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          value: 'servicelist_code',
          sortable: false
        }, {
          text: 'ชื่อคอร์ส/หัตถการ',
          align: 'center',
          value: 'servicelist_name',
          sortable: false
        }, {
          text: 'จำนวนครั้ง/คอร์ส',
          align: 'center',
          value: 'servicelist_amount',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersCourseList () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          value: 'courselist_code',
          sortable: false
        }, {
          text: 'ชื่อคอร์ส/หัตถการ',
          align: 'center',
          value: 'courselist_name',
          sortable: false
        }, {
          text: 'แก้ไขรายละเอียด',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    headersEditCourseList () {
      return [
        {
          text: 'รายการ',
          align: 'center',
          value: 'editcoursedrug_name',
          sortable: false
        }, {
          text: 'จำนวนครั้ง/ต่อคอร์ส',
          align: 'center',
          value: 'editcoursedrug_amount',
          sortable: false
        }, {
          text: 'เหลือ',
          align: 'center',
          value: 'editcoursedrug_remain',
          sortable: false
        }, {
          text: 'หน่วยนับ',
          align: 'center',
          value: 'editcoursedrug_unit',
          sortable: false
        }, {
          text: 'จำนวนที่ใช้',
          align: 'center',
          value: 'editcoursedrug_use',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    const self = this
    self.initOperativecourseData()
    self.initServiceListData()
    self.initCourseListData()
  },
  methods: {
    computedCustomerAge () {
      const self = this
      const year = moment().diff(self.customerData.birthdate, 'years')
      self.customerData.age = year
      return year
    },
    addOperativecourseData () {
      const self = this
      console.log('add: ', self.OperativecourseData)
    },
    editOperativecourseData () {
      const self = this
      console.log('edit: ', self.OperativecourseData)
    },
    initOperativecourseData () {
      const self = this
      self.loaddataOperativecourse = true
      var temp = []
      temp.push({
        _id: '000',
        operativecourse_code: 'BTS012',
        operativecourse_name: 'Botox กรามไม่จำกัดยูนิต+ย้ำฟรี',
        operativecourse_amount: 1,
        operativecourse_unit: 'เข็ม',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      }, {
        _id: '001',
        operativecourse_code: 'BTM013',
        operativecourse_name: 'Botox กราม+เหนียง+หน้าไม่จำกัดยูนิต+ย้ำฟรี+หน้าใส 2 เข็ม',
        operativecourse_amount: 1,
        operativecourse_unit: 'เข็ม',
        operativecourse_selection: 'active',
        operativecourse_selection_bool: true
      }, {
        _id: '002',
        operativecourse_code: 'PCL019',
        operativecourse_name: 'ไหมนางฟ้าPCL+PLLA 4 เส้น',
        operativecourse_amount: 1,
        operativecourse_unit: 'เส้น',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      }, {
        _id: '003',
        operativecourse_code: '8D0024',
        operativecourse_name: 'รักษาหลุมสิว',
        operativecourse_amount: 1,
        operativecourse_unit: 'ครั้ง',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      }, {
        _id: '004',
        operativecourse_code: 'TD0013',
        operativecourse_name: 'ไหมทอร์นาโด 4 เส้น',
        operativecourse_amount: 1,
        operativecourse_unit: 'เส้น',
        operativecourse_selection: 'active',
        operativecourse_selection_bool: true
      }, {
        _id: '005',
        operativecourse_code: 'TD0015',
        operativecourse_name: 'ไหมทอร์นาโด 4 เส้น+ยกกระชับใบหน้า',
        operativecourse_amount: 1,
        operativecourse_unit: 'เส้น',
        operativecourse_selection: 'inactive',
        operativecourse_selection_bool: false
      })
      setTimeout(() => {
        self.dataOperativecourse = temp
        self.loaddataOperativecourse = false
      }, 300)
    },
    initServiceListData () {
      const self = this
      self.loaddataServiceList = true
      var temp = []
      temp.push({
        _id: '000',
        servicelist_code: 'BTM013',
        servicelist_name: 'Botox กราม+เหนียง+หน้าไม่จำกัดยูนิต+ย้ำฟรี+หน้าใส 2 เข็ม',
        servicelist_amount: 1,
        servicelist_unit: 'เข็ม'
      }, {
        _id: '001',
        servicelist_code: 'TD0013',
        servicelist_name: 'ไหมทอร์นาโด 4 เส้น',
        servicelist_amount: 1,
        servicelist_unit: 'เส้น'
      })
      setTimeout(() => {
        self.dataServiceList = temp
        self.loaddataServiceList = false
      }, 300)
    },
    deleteServiceListItem (item) {
      const self = this
      self.loaddataServiceList = true
      var itemindex = null
      itemindex = self.dataServiceList.findIndex(el => {
        return el._id === item._id
      })
      swal(`ต้องการลบรายการใช้บริการ "${item.servicelist_name} (${item.servicelist_code})" ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: 'ไม่ใช่',
          confirm: {
            text: 'ใช่',
            value: 'confirm'
          }
        }
      }).then((value) => {
        if (value === 'confirm') {
          swal('สำเร็จ', 'ลบข้อมูลสำเร็จ', 'success', {
            button: false,
            timer: 2000
          }).then(() => {
            self.dataServiceList.splice(itemindex, 1)
            self.loaddataServiceList = false
          })
        } else {
          swal('คำเตือน', 'ยังไม่ได้ลบข้อมูล', 'warning', {
            button: false,
            timer: 3000
          })
          self.initServiceListData()
        }
      })
    },
    addServiceListFunc () {
      swal('สำเร็จ', 'เพิ่มรายการใช้บริการสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    },
    cancelServiceListFunc () {
      swal('สำเร็จ', 'ยกเลิกรายการใช้บริการสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    },
    initCourseListData () {
      const self = this
      self.loaddataCourseList = true
      var temp = []
      temp.push({
        _id: '000',
        courselist_code: 'BTS012',
        courselist_name: 'Botox กรามไม่จำกัดยูนิต+ย้ำฟรี',
        courselist_amount: 1,
        courselist_unit: 'เข็ม'
      }, {
        _id: '001',
        courselist_code: 'BTM013',
        courselist_name: 'Botox กราม+เหนียง+หน้าไม่จำกัดยูนิต+ย้ำฟรี+หน้าใส 2 เข็ม',
        courselist_amount: 1,
        courselist_unit: 'เข็ม'
      }, {
        _id: '002',
        courselist_code: 'PCL019',
        courselist_name: 'ไหมนางฟ้าPCL+PLLA 4 เส้น',
        courselist_amount: 1,
        courselist_unit: 'เส้น'
      }, {
        _id: '003',
        courselist_code: '8D0024',
        courselist_name: 'รักษาหลุมสิว',
        courselist_amount: 1,
        courselist_unit: 'ครั้ง'
      }, {
        _id: '004',
        courselist_code: 'TD0013',
        courselist_name: 'ไหมทอร์นาโด 4 เส้น',
        courselist_amount: 1,
        courselist_unit: 'เส้น'
      }, {
        _id: '005',
        courselist_code: 'TD0015',
        courselist_name: 'ไหมทอร์นาโด 4 เส้น+ยกกระชับใบหน้า',
        courselist_amount: 1,
        courselist_unit: 'เส้น'
      })
      setTimeout(() => {
        self.dataCourseList = temp
        self.itemsCourseList = temp
        self.loaddataCourseList = false
      }, 300)
    },
    editCourseListItem (item) {
      const self = this
      self.loaddataEditCourseList = true
      var temp = []
      var tempEdit = ''
      temp.push({
        _id: '000',
        editcoursedrug_code: 'DRUG001',
        editcoursedrug_type: 'ยา',
        editcoursedrug_name: 'Botox กราม',
        editcoursedrug_amount: 1,
        editcoursedrug_remain: 1,
        editcoursedrug_unit: 'คู่',
        editcoursedrug_use: 1.5
      }, {
        _id: '001',
        editcoursedrug_code: 'DRUG002',
        editcoursedrug_type: 'ยา',
        editcoursedrug_name: 'เมโสหน้าใส',
        editcoursedrug_amount: 2,
        editcoursedrug_remain: 10,
        editcoursedrug_unit: 'CC',
        editcoursedrug_use: 5
      })
      tempEdit = self.dataCourseList.filter(el => {
        return el.courselist_code === item.courselist_code
      })
      self.title_select_filter_courselist = tempEdit[0]?.courselist_name || ''
      setTimeout(() => {
        self.dataEditCourseList = temp
        self.canaddtoeditcourselist = false
        self.loaddataEditCourseList = false
      }, 300)
    },
    deleteEditCourseListItem (item) {
      const self = this
      self.loaddataEditCourseList = true
      var itemindex = null
      itemindex = self.dataEditCourseList.findIndex(el => {
        return el._id === item._id
      })
      swal(`ต้องการลบรายการ "${item.editcoursedrug_name} (${item.editcoursedrug_type})" ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: 'ไม่ใช่',
          confirm: {
            text: 'ใช่',
            value: 'confirm'
          }
        }
      }).then((value) => {
        if (value === 'confirm') {
          swal('สำเร็จ', 'ลบข้อมูลสำเร็จ', 'success', {
            button: false,
            timer: 2000
          }).then(() => {
            self.dataEditCourseList.splice(itemindex, 1)
            self.loaddataEditCourseList = false
          })
        } else {
          swal('คำเตือน', 'ยังไม่ได้ลบข้อมูล', 'warning', {
            button: false,
            timer: 3000
          })
          self.loaddataEditCourseList = false
        }
      })
    },
    addDrugToCourseList () {
      swal('สำเร็จ', 'เพิ่มยาที่ต้องการเพิ่มในคอร์สสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    },
    addMedicalToCourseList () {
      swal('สำเร็จ', 'เพิ่มอุปกรณ์ที่ต้องการเพิ่มในคอร์สสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    }
  }
}
</script>

<style scoped>
@import "../../../styles/DrugsAndMedical_AddStock.css";
@import "../../../styles/Service.css";
</style>
